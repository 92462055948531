import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const EmailIcon = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          email
        }
      }
    }
  `)

  return (
    <a href={`mail-to:${data.site.siteMetadata.email}`} target="_top">
      <span className="icon email">
        <svg
          version="1.1"
          className="email-icon-svg"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          xmlSpace="preserve"
        >
          <g>
            <path d="M86.964,86.357c2.023,0,3.776-0.668,5.266-1.987L66.732,58.871   c-0.612,0.438-1.205,0.864-1.765,1.269c-1.908,1.406-3.457,2.503-4.646,3.289c-1.189,0.788-2.771,1.591-4.746,2.411   c-1.976,0.821-3.817,1.23-5.525,1.23h-0.05h-0.05c-1.708,0-3.549-0.409-5.525-1.23c-1.976-0.82-3.558-1.623-4.746-2.411   c-1.189-0.786-2.737-1.883-4.646-3.289c-0.532-0.39-1.122-0.818-1.76-1.276L7.77,84.37c1.49,1.319,3.244,1.987,5.267,1.987H86.964z   " />
            <path d="M10.074,42.814c-1.908-1.272-3.6-2.729-5.073-4.37v38.795l22.474-22.474   C22.979,51.626,17.186,47.647,10.074,42.814z" />
            <path d="M89.978,42.814c-6.841,4.63-12.655,8.616-17.443,11.96L95,77.24V38.444   C93.56,40.052,91.886,41.508,89.978,42.814z" />
            <path d="M86.964,15.643H13.037c-2.579,0-4.562,0.871-5.951,2.611c-1.39,1.741-2.084,3.918-2.084,6.529   c0,2.109,0.921,4.394,2.762,6.856c1.841,2.461,3.8,4.394,5.876,5.8c1.138,0.804,4.57,3.19,10.296,7.157   c3.091,2.142,5.779,4.009,8.089,5.62c1.969,1.372,3.667,2.56,5.069,3.546c0.161,0.113,0.414,0.294,0.75,0.534   c0.362,0.26,0.82,0.59,1.385,0.998c1.088,0.787,1.992,1.423,2.712,1.909c0.719,0.486,1.59,1.029,2.612,1.632   c1.021,0.602,1.984,1.055,2.888,1.356c0.904,0.301,1.741,0.452,2.511,0.452h0.05h0.05c0.77,0,1.607-0.151,2.511-0.452   c0.904-0.301,1.866-0.753,2.888-1.356c1.021-0.603,1.891-1.147,2.612-1.632c0.72-0.486,1.624-1.122,2.712-1.909   c0.564-0.408,1.022-0.738,1.384-0.997c0.336-0.241,0.589-0.421,0.751-0.535c1.092-0.76,2.794-1.943,5.082-3.532   c4.164-2.893,10.296-7.151,18.422-12.791c2.444-1.707,4.486-3.767,6.127-6.177C94.179,28.852,95,26.324,95,23.679   c0-2.21-0.796-4.101-2.385-5.676C91.024,16.43,89.14,15.643,86.964,15.643z" />
          </g>
        </svg>
      </span>
    </a>
  )
}

export default EmailIcon
